@import "./board.scss";
@import "./languageswitcher.scss";
@import "./messaging.scss";
@import "./tutorial.scss";

* {
  box-sizing: border-box;
  // outline: 1px solid lime !important;
}
.ant-btn-primary {
  background-color: #4f8af7 !important;
  border: 1px solid #4f8af7;
  &:hover {
    color: #4f8af7 !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
  background-color: #4f8af7;
}
button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}

.my-vacancies-grid > .ant-ribbon-wrapper {
  width: min-content;
  min-width: 60%;
  max-width: 100%;
}

.split-view-view {
  overflow: scroll !important;
  min-height: 80vh;
}
.split-view-view-visible,
.split-view {
  min-height: 80vh;
}
.ReactModal__Overlay {
  z-index: 999;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: white ;
  border: 1px solid #363232 !important;
}

.ant-float-btn .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  margin: 0% !important;
  width: auto !important;
}

@media (min-width: 1024px) {
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.darkModeCorrector > input {
  background: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.animate-main {
  animation: rebounce 3s infinite;

  @keyframes rebounce {
    0%,
    100% {
      transform: translateY(-10px);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.ant-popconfirm-buttons{
  display:flex
}

.ant-avatar{
display: flex;
text-align: center;
align-items: center;
justify-content: center;
}

.ant-modal-footer{
  display:flex;
  justify-content: end;
}


