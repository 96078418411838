.mentions {
  margin: 0.5em 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: Inter;
  font-size: 14pt; 
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 200px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid #d9d9d9;
  padding: 9px; 
  outline: 0;
  line-height: 29.35px;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  font-family: Inter, sans-serif;

}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  color: black !important;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}
