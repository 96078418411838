:root {
  --indigo-50: #e6e8ff;
  --indigo-100: #b2baff;
  --indigo-200: #8f9dff;
  --indigo-300: #6b6fff;
  --indigo-400: #4a4eff;
  --indigo-500: #0538FF;
  --indigo-600: #4b49f5;
  --indigo-700: #6B57F5;
  --indigo-800: #5c4bca;
  --indigo-900: #4a3a9f;
  --indigo-950: #3e2a8e;
}
:root {
  --blue-50: #e6e8ff;
  --blue-100: #b2baff;
  --blue-200: #8f9dff;
  --blue-300: #6b6fff;
  --blue-400: #4a4eff;
  --blue-500: #0538FF;
  --blue-600: #4b49f5;
  --blue-700: #6B57F5;
  --blue-800: #5c4bca;
  --blue-900: #4a3a9f;
  --blue-950: #3e2a8e;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-width: 250px;
  background-color: #eee;
  @apply font-inter; 
}

.text-red {
  color: #ff2400;
}

.gradient-text {
  background: linear-gradient(90deg, #0538FF, #6B57F5) ;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Custom scrollbar styles */
*::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  height: 7px; /* Width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded corners on the scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scroll thumb on hover */
}

/* For IE, Edge, and Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.15) inset;
  }
  50% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.35) inset;
  }
  100% {
    box-shadow: 0 0 100vh 100vw rgba(0, 0, 0, 0.15) inset;
  }
}

.splash {
  animation: pulse 2s infinite ease-in-out;
}

.ant-modal-content {
  min-width: 300px;
}

.alice-carousel__dots-item {
  cursor: pointer;
}

@font-face {
  font-display: swap;
  font-style: normal;
  src: url(./fonts/InterSemiBold.ttf);
  font-family: "Inter";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(./fonts/InterRegular.ttf);
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(./fonts/InterMedium.ttf);
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(./fonts/InterBold.ttf);
  font-family: "Inter";
  font-weight: 700;
}

/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="search"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
  border-color: transparent;
}
@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[1216px];
  }

  .container-sm {
    @apply w-full mx-auto max-w-7xl;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.controls {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
}

.transcript-container {
  position: relative;
  top: 0px;
  right: 0px;
  height: 100vh;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  z-index: 10;
  width: 450px;
}

.toggle-transcript {
  display: none;
}

@media (max-width: 768px) {
  .transcript-container {
    max-height: 40vh;
  }

  .toggle-transcript {
    display: block;
    margin-bottom: 10px;
  }

  .transcript {
    display: none;
  }
}

.ant-steps-item-title {
  font-size: 10px !important;
  font-weight: 600 !important
}

.ant-steps-item-content {
  margin-top: 5px !important;

}

.ant-steps-item-icon{
  height: 16px !important;
  width: 16px !important;
  line-height: 15px !important;
  background-color: #0538FF !important;
  color: #fff !important
  
}


.ant-steps-item-tail{
  top: 0 !important;
  margin-inline-start: 50px !important; 
}

